<template>
  <div ref="chart" :style="{height:height,width:width}"></div>
</template>
<script>
import {debounce} from 'lodash'
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
        type:Object,
        default:{
            xAxisData: [],
            seriesData:[]
        }
    },
    legendData:{
        type:Array,
        default:[]
    },
    units:{
        type:Array,
        default:[]
    },
    yAxis:{
        type:Array,
        default:[]
    },
    yAxisIndex:{
      type:Array,
      default:0
    },
    width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
  },
  data() {
    return {
      color:['rgba(35,142,255,0.7)','rgba(62,235,255,0.7)','rgba(255,234,0,0.7)','rgba(52,250,89,0.7)'],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    }
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      let arr = _this.chartData.seriesData || [];
      let options =  {
        tooltip: {
          transitionDuration:0,
          trigger: "axis",
          backgroundColor:"#09214d",
          borderWidth:1,
          borderColor:"#7ccaff", 
          axisPointer:{
              type:"line",
              lineStyle:{
                type: 'dashed',
                  color:"rgba(102,163,215,0.3)"
              }
          },
          formatter:function(params){
            //console.log(params)
            let html = ""
            html+= params[0].name + '<br>'
            params.forEach((res,i)=>{
              //html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              if(res.seriesName.indexOf("笔") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>笔<br>'
              }else if(res.seriesName.indexOf("金额") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>元<br>'
              }else if(res.seriesName.indexOf("比") != -1){
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>%<br>'
              }else{
                html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              }
            })
            return html;
          }
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "40",
          right: "40",
          bottom: "40",
          containLabel: true,
        },
        legend: {
          top:10,
          right: "4%",
          itemGap: 40,
          icon: "circle",
          data: _this.legendData,
          textStyle: {
              fontSize: 14,
              color: "#5bd5f7"
          }
        },
        xAxis: {
          data: _this.chartData.xAxisData,
          splitNumber:7,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
            //name:item,
            min:null,
            max:null,
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          },
        // '资源申请数','已提供资源数','需求项超时数'
        series: {
            name: arr[0].name,
            // yAxisIndex:_this.yAxisIndex[i],
            type: "line",
            showSymbol: false,
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              color: 'rgba(35,142,255,0.7)',
              width:1
            },
            itemStyle: {
              color: 'rgba(35,142,255,0.7)',
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(35,142,255,0.7)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#0000", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            label:{
              formatter:'{a}'+arr[0].unit
            },
            data: arr[0].data,
          }
      }
      // 绘制图表
      this.chart.setOption(options);
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>